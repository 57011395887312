import { useEffect, useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import {  CONTACT_INFOS_API } from "../../../Utilities/APIs"
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"
import EmailInput from "../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput"


function UpdateContactInfo({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    useEffect(() => {
        async function fetchAndSetContactInfo() {
            const { data } = await axios.get(CONTACT_INFOS_API + targetID)
            setAddress(data.address);
            setEmail(data.email);
            setPhone(data.phone);
        }
        fetchAndSetContactInfo()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const contactInfos = { address, phone, email }

        console.log(contactInfos);
        const response = await axios.patch(CONTACT_INFOS_API + targetID, contactInfos)

        if (response) {
            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`Address`}
                value={address}
                placeholder={`Enter Address`}
                setState={setAddress}
            />
            <NumberInput
                label={`Phone`}
                value={phone}
                placeholder={`Enter Phone`}
                setState={setPhone}
            />

            <EmailInput
                label={`Email`}
                value={email}
                placeholder={`Enter Email`}
                setState={setEmail}
            />

            <FormSubmitButton text='Create Client' />
        </Form>
    )
}

export default UpdateContactInfo