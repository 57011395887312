import {  TESTIMONIAL_API } from '../../../Utilities/APIs'
import DINCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DINCRUD/DINCRUD'

function Testimonials() {
    return (
        <DINCRUD
        // common props
        api={TESTIMONIAL_API}
        screenTopicSingular='Testimonial'
        screenTopicPlural='Testimonials'


        // crud header props
        showTotalInHeading


        // crud props
        allowCreate
        allowUpdate
        allowDelete

    />
    )
}

export default Testimonials