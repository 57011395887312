import { useState, useEffect } from "react"
import axios from "axios"
import {  CONTACT_INFOS_API } from "../../../Utilities/APIs"


function ViewContactInfo({

    //common props
    targetID, employee

}) {

    const [contactInfo, setContactInfo] = useState(null)
    console.log(contactInfo)
    useEffect(() => {

        async function fetchAndSetInfo() {
            const { data } = await axios.get(CONTACT_INFOS_API  + targetID)
            setContactInfo(data);
        }
        fetchAndSetInfo()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {contactInfo &&
                <>
                    <h1>Address</h1>
                    <p>{contactInfo.address}</p>

                    <h1>Email</h1>
                    <p>{contactInfo.email}</p>

                    <h1>Phone</h1>
                    <p>{contactInfo.phone}</p>

                </>
            }
        </div>
    )
}

export default ViewContactInfo