import { useState, useEffect } from "react"
import axios from "axios"

import { FEATURED_RECENT_PURCHASE_API } from "../../../../Utilities/APIs"

function ViewFeaturedRecentPurchase({

    //common props
    targetID, employee

}) {

    const [featuredRecentPurchase, setFeaturedRecentPurchase] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        async function fetchAndSetFeaturedRecentPurchase() {

            const { data } = await axios.get(FEATURED_RECENT_PURCHASE_API + 'getSingleFeaturedRecentPurchase/' + targetID ,config)
            setFeaturedRecentPurchase(data);
        }
        fetchAndSetFeaturedRecentPurchase()
    }, [targetID ,employee.token])

    return (
        <div className="crud_view_content">

            {featuredRecentPurchase &&
                <>
                    <h1>Name</h1>
                    <p>{featuredRecentPurchase?.recentPurchase?.name}</p>

                    <h1>Precedence</h1>
                    <p>{featuredRecentPurchase.precedence}</p>

                </>
            }
        </div>
    )
}

export default ViewFeaturedRecentPurchase