import { useState, useEffect } from "react"
import axios from "axios"
import { ABOUT_API, IMAGE_URL } from "../../../../Utilities/APIs"

function ViewAbout({

    //common props
    targetID

}) {

    const [about, setAbout] = useState(null)

    useEffect(() => {

        async function fetchAndSetAbout() {

            const { data } = await axios.get(ABOUT_API + 'getSingleAbout/' + targetID)
            setAbout(data);
        }
        fetchAndSetAbout()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {about &&
                <>
                    <h1>Image</h1>
                    <img src={IMAGE_URL + about.image} alt="" />

                    <h1>Description</h1>
                    <div
                        dangerouslySetInnerHTML={{ __html: about.description }}
                    />

                </>
            }
        </div>
    )
}

export default ViewAbout