import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import {  RiBuilding2Line, RiCommandLine, RiImageLine } from 'react-icons/ri'

function Dashboard() {
  return (
    <NavCardList numOfCards={'four'}>
      <NavCard cardName={'Client Journey'} navCardLink={'/clientJourney'} ><RiImageLine /></NavCard>
      <NavCard cardName={'Process'} navCardLink={'/process'} ><RiCommandLine /></NavCard>
      <NavCard cardName={'Recent Purchase'} navCardLink={'/recentPurchase'} ><RiBuilding2Line /></NavCard>
    
    </NavCardList>
  )
}

export default Dashboard