import { useState } from "react"
import axios from "axios"
// import { RiVideoAddLine } from "react-icons/ri"

import { APPROACH_API,  } from "../../../Utilities/APIs"
import Form from "../../Partials/Layouts/Forms/Form"

import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
// import Modal from "../../Partials/Elements/Modal/Modal"
// import MediaGallery from "../../Partials/Layouts/MediaGallery/MediaGallery"

import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor"
import { useEffect } from "react"

function UpdateApproach({targetID, setShowUpdateForm, setShowModal, triggerFetch }) {

    // const [showSecondModal, setShowSecondModal] = useState(false);
    // const [showMediaGallery, setShowMediaGallery] = useState(false);
    const [activeTab, setActiveTab] = useState(2);

    const [description, setDescription] = useState('')
 
    useEffect(() => {

        async function fetchAndSetApproach() {

            const { data } = await axios.get(APPROACH_API + 'getSingleApproach/' + targetID)
            setDescription(data.description);
        }
        fetchAndSetApproach()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('description', description)

        const response = await axios.patch(APPROACH_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }


    // function handleClose() {
    //     setShowSecondModal(false)
    //     setShowMediaGallery(false)
    // }

    return (
        <>
            <div className="navtab_action">
                <div className="nav_tab">
                    {/* <button
                        type="button"
                        className={activeTab === 1 ? 'active' : ''}
                        onClick={() => setActiveTab(1)}
                    >
                        <span>details</span>
                    </button> */}
                    <button
                        type="button"
                        className={activeTab === 2 ? 'active' : ''}
                        onClick={() => setActiveTab(2)}
                    >
                        <span>write up</span>
                    </button>
                </div>
                {/* <button
                    className="btn_snmd_open"
                    onClick={() => {
                        setShowSecondModal(true)
                        setShowMediaGallery(true)
                    }}
                >
                    <RiVideoAddLine />
                    <span>Media Gallery</span>
                </button> */}
            </div>

            <Form onSubmit={handleSubmit} hasImage>
                {/* <div className={`nav_content ${activeTab === 1 ? 'active' : ''}`}>
                    
                   

                </div> */}
                <div className={`nav_content ${activeTab === 2 ? 'active' : ''}`}>
                    <CustomEditor
                        setState={setDescription}
                        data={description}
                    />
                </div>


                <FormSubmitButton text='Update Approach' />
            </Form>

           
        </>
    )
}

export default UpdateApproach