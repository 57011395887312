import { useEffect, useState } from "react"
import axios from "axios"

import { FEATURED_TESTIMONIAL_API, TESTIMONIAL_API } from "../../../../Utilities/APIs"
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../Partials/Layouts/Forms/Form"


function CreateFeaturedTestimonial({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

    const [testimonial, setTestimonial] = useState('')
    const [precedence, setPrecedence] = useState('')

    const [testimonials, setTestimonials] = useState(null)

  

    useEffect(() => {

        async function fetchAndSetTestimonial() {
            const { data } = await axios.get(TESTIMONIAL_API)
            setTestimonials(data)
        }
        fetchAndSetTestimonial()


    }, [])

    function precedenceSelectOptions() {
        const rows = [];
        for (let i = 1; i <= 6; i++) {
            rows.push(<SelectOption key={i} optionText={i} optionValue={i} />);
        }
        return rows;
    }

    async function handleSubmit(e) {

        e.preventDefault()

         const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }
        const itemData = new FormData()

        itemData.append('testimonial', testimonial)
        itemData.append('precedence', precedence)



        const response = await axios.post(FEATURED_TESTIMONIAL_API, itemData ,config)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <div className="input_group">
                <label className='input_field_label'>
                   Testimonial
                </label>
                <select
                    value={testimonial}
                    onChange={(e) => setTestimonial(e.target.value)}
                    className="input_field"
                    placeholder="propertyCategory"
                >
                    <option value="0" hidden>Select Testimonial</option>
                    {testimonials?.map((testimonial => (
                        <option value={testimonial._id} key={testimonial._id}>
                            {testimonial?.name}
                        </option>
                    )))}
                </select>
            </div>
            <SelectInput value={precedence} setState={setPrecedence}>
                <SelectOption optionValue={0} optionText="Select Precedence" />
                {precedenceSelectOptions()}
            </SelectInput>


            <FormSubmitButton text='Create Featured Testimonial' />
        </Form>
    )
}

export default CreateFeaturedTestimonial