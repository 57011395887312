import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import axios from "axios"

import {  FEATURED_TESTIMONIAL_API } from "../../../../Utilities/APIs"
// import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton"
// import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
import Modal from "../../../Partials/Elements/Modal/Modal"
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate"
import ViewFeaturedTestimonial from "./ViewFeaturedTestimonial"
import CreateFeaturedTestimonial from "./CreateFeaturedTestimonial"
import UpdateFeaturedTestimonial from "./UpdateFeaturedTestimonial"



function FeaturedTestimonial() {

    const [featuredTestimonials, setFeaturedTestimonials] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)
    const { employee } = useSelector(state => state.auth)

    useEffect(() => {

        async function fetchAndSetFeaturedTestimonials() {
            const { data } = await axios.get(FEATURED_TESTIMONIAL_API)
            setFeaturedTestimonials(data)
        }
        fetchAndSetFeaturedTestimonials()


    }, [toggleFetch])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }


    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Featured Testimonials (${featuredTestimonials?.length} in total)`}</h1>
                    {/* <CreateButton
                        screenTopicSingular='Featured Testimonial'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    /> */}
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Name' />
                        <CRUDth th='Precedence' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {featuredTestimonials?.map(featuredArticle => (
                            <CRUDTableRow key={featuredArticle._id}>
                                <ShortTextCell text={featuredArticle?.testimonial?.name} />
                                <ShortTextCell text={featuredArticle?.precedence} />


                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={featuredArticle._id}
                                        setTargetID={setTargetID}
                                    />
                                    <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={featuredArticle._id}
                                        setTargetID={setTargetID}
                                    />
                                    {/* <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={featuredArticle._id}
                                        setTargetID={setTargetID}
                                    /> */}

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Featured Testimonial`) ||
                        (showUpdateForm && `Update Featured Testimonial`) ||
                        (showDeleteSection && `Delete Featured Testimonial`)
                    }
                >
                    {
                        showCreateForm &&
                        <CreateFeaturedTestimonial
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showUpdateForm &&
                        <UpdateFeaturedTestimonial
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showDeleteSection &&
                        <CRUDDeleteBoilerPlate
                            api={FEATURED_TESTIMONIAL_API}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Featured Testimonial`)
                    }
                >
                    <ViewFeaturedTestimonial
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            }
        </>
    )
}

export default FeaturedTestimonial