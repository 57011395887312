import { RiBook2Line, RiCopperCoinLine, RiTeamLine } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function AboutScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'About'} navCardLink={'/about'} ><RiBook2Line /></NavCard>
            <NavCard cardName={'Approach'} navCardLink={'/approach'} ><RiCopperCoinLine /></NavCard>
            <NavCard cardName={'Team'} navCardLink={'/teams'} ><RiTeamLine /></NavCard>
        </NavCardList>
    )
}

export default AboutScreen