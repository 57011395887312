import { useState, useEffect } from "react"
import axios from "axios"
import { FAQ_API } from "../../../Utilities/APIs"
import Form from "../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"

function UpdateFAQs({ targetID, setShowUpdateForm, setShowModal, triggerFetch }) {
    const [activeTab, setActiveTab] = useState(2);

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {

        async function fetchAndSetFaq() {

            const { data } = await axios.get(FAQ_API + 'getSingleFaq/' + targetID)
            setDescription(data.description);
            setName(data.name);
        }
        fetchAndSetFaq()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('description', description)

        const response = await axios.patch(FAQ_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }


    return (
        <Form onSubmit={handleSubmit}>
            <ShortTextInput
                label={'Name'}
                placeholder={`Enter Name`}
                value={name}
                setState={setName}
            />

            <div className="navtab_action">
                <div className="nav_tab">
                    <button
                        type="button"
                        className={activeTab === 2 ? 'active' : ''}
                        onClick={() => setActiveTab(2)}
                    >
                        <span>write up</span>
                    </button>
                </div>
            </div>

            <div className={`nav_content ${activeTab === 2 ? 'active' : ''}`}>
                <CustomEditor
                    setState={setDescription}
                    data={description}
                />
            </div>

            <FormSubmitButton text='Update FAQs' />
        </Form>
    )
}

export default UpdateFAQs