import { useState, useEffect } from "react"
import axios from "axios"
import { IMAGE_URL, MEET_THE_TEAM_API } from "../../../Utilities/APIs"

function ViewTeam({

    //common props
    targetID, employee

}) {

    const [team, setTeam] = useState(null)

    useEffect(() => {

        async function fetchAndSetTeam() {

            const { data } = await axios.get(MEET_THE_TEAM_API + 'getSingleMeetTheTeam/' + targetID)
            setTeam(data);
        }
        fetchAndSetTeam()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {team &&
                <>
                    <h1>Name</h1>
                    <p>{team.name}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + team.image} alt="" />

                    <h1>Designation</h1>
                    <p>{team.designation}</p>

                    <h1>Description</h1>
                    <div
                        dangerouslySetInnerHTML={{ __html: team.description }}
                    />

                    <h1>Precedence</h1>
                    <p>{team.precedence}</p>

                </>
            }
        </div>
    )
}

export default ViewTeam