import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import axios from "axios"

import { ABOUT_API, IMAGE_URL, } from "../../../../Utilities/APIs"
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
// import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton"
import Modal from "../../../Partials/Elements/Modal/Modal"
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate"
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
import ViewAbout from "./ViewAbout"
import UpdateAbout from "./UpdateAbout"
import CreateAbout from "./CreateAbout"
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell"


function About() {

    const [abouts, setAbouts] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)
    const { employee } = useSelector(state => state.auth)

    useEffect(() => {

        async function fetchAndSetAbouts() {
            const { data } = await axios.get(ABOUT_API)
            setAbouts(data)
        }
        fetchAndSetAbouts()


    }, [toggleFetch])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }


    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Abouts (${abouts?.length} in total)`}</h1>
                    {abouts?.length < 0 &&
                        <CreateButton
                            screenTopicSingular='About'
                            setShowModal={setShowModal}
                            setShowCreateForm={setShowCreateForm}
                        />
                    }
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Image' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {abouts?.map(about => (
                            <CRUDTableRow key={about._id}>
                                <ImageCell imgSrc={IMAGE_URL + about.image} />
                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={about._id}
                                        setTargetID={setTargetID}
                                    />
                                    <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={about._id}
                                        setTargetID={setTargetID}
                                    />
                                    <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={about._id}
                                        setTargetID={setTargetID}
                                    />

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create About`) ||
                        (showUpdateForm && `Update About`) ||
                        (showDeleteSection && `Delete About`)
                    }
                >
                    {
                        showCreateForm &&
                        <CreateAbout
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showUpdateForm &&
                        <UpdateAbout
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showDeleteSection &&
                        <CRUDDeleteBoilerPlate
                            api={ABOUT_API}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View About`)
                    }
                >
                    <ViewAbout
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            }
        </>
    )
}

export default About