import { useEffect, useState } from "react"
import axios from "axios"

import { RECENT_PURCHASE_API } from "../../../Utilities/APIs"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"


function UpdateRecentPurchase({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {
    const [name, setName] = useState('')
    const [image, setImage] = useState('')
    const [location, setLocation] = useState('')
    const [price, setPrice] = useState('')
    const [rent, setRent] = useState('')
    const [bed, setDed] = useState('')
    const [bath, setBath] = useState('')
    const [sqFt, setSqFt] = useState('')
    const [garage, setGarage] = useState('')


    useEffect(() => {

        async function fetchAndSetRecentPurchase() {

            const { data } = await axios.get(RECENT_PURCHASE_API + 'getSingleRecentPurchase/' + targetID)
            setName(data.name);
            setImage(data.image);
            setLocation(data.location);
            setPrice(data.price);
            setRent(data.rent);
            setDed(data.bed);
            setBath(data.bath);
            setGarage(data.garage);
            setSqFt(data.sqFt);
            setGarage(data.garage);
        }
        fetchAndSetRecentPurchase()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('image', image)
        itemData.append('location', location)
        itemData.append('price', price)
        itemData.append('rent', rent)
        itemData.append('bed', bed)
        itemData.append('bath', bath)
        itemData.append('sqFt', sqFt)
        itemData.append('garage', garage)


        const response = await axios.patch(RECENT_PURCHASE_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`Name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowUpdateImage
            >
                Upload Card Image
            </ImageInput>

            <ShortTextInput
                label={`Location`}
                value={location}
                placeholder={`Enter Location`}
                setState={setLocation}
            />
            <ShortTextInput
                label={`Price`}
                value={price}
                placeholder={`Enter Price`}
                setState={setPrice}
            />
            <ShortTextInput
                label={`Rent`}
                value={rent}
                placeholder={`Enter Rent`}
                setState={setRent}
            />
            <ShortTextInput
                label={`Bed`}
                value={bed}
                placeholder={`Enter Bed`}
                setState={setDed}
            />
            <ShortTextInput
                label={`Bath`}
                value={bath}
                placeholder={`Enter Bath`}
                setState={setBath}
            />
            <ShortTextInput
                label={`Garage`}
                value={garage}
                placeholder={`Enter Garage`}
                setState={setGarage}
            />

            <ShortTextInput
                label={`Square`}
                value={sqFt}
                placeholder={`Enter Square`}
                setState={setSqFt}
            />
           

            <FormSubmitButton text='Update Recent Purchase' />
        </Form>
    )
}

export default UpdateRecentPurchase