import { RECENT_PURCHASE_API, RECENT_PURCHASE_FEATURES_API } from "../../../Utilities/APIs"
import PcNSCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/NSCRUD/PcNSCRUD"

function RecentPurchaseFeatures() {
    
    return (
        <PcNSCRUD
            // parentChild props
            parentDBField="recentPurchase"

            // common props
            api={RECENT_PURCHASE_FEATURES_API}
            screenTopicSingular="Recent Purchase Feature"
            screenTopicPlural="Recent Purchase Features"

            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate
            allowDelete

            // select props
            selectApi={RECENT_PURCHASE_API}
            selectType='parent-child'
            selectDBField="recentPurchase"
            selectName="Recent Purchase"
        />
    )
}

export default RecentPurchaseFeatures