import { useState } from "react"
import axios from "axios"
// import { RiVideoAddLine } from "react-icons/ri"

import { MEET_THE_TEAM_API, } from "../../../Utilities/APIs"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
// import Modal from "../../Partials/Elements/Modal/Modal"
// import MediaGallery from "../../Partials/Layouts/MediaGallery/MediaGallery"


import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor"
import SelectOption from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"
import SelectInput from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import { useEffect } from "react"

function UpdateTeam({ targetID, setShowUpdateForm, setShowModal, triggerFetch }) {

   
    const [activeTab, setActiveTab] = useState(1);

    const [name, setName] = useState('')
    const [designation, setDesignation] = useState('')
    const [image, setImage] = useState('')
    const [description, setDescription] = useState('')
    const [precedence, setPrecedence] = useState('')

    function precedenceSelectOptions() {
        const rows = [];
        for (let i = 1; i <= 6; i++) {
            rows.push(<SelectOption key={i} optionText={i} optionValue={i} />);
        }
        return rows;
    }

    useEffect(() => {

        async function fetchAndSetTeam() {

            const { data } = await axios.get(MEET_THE_TEAM_API + 'getSingleMeetTheTeam/' + targetID)
            setName(data.name);
            setDesignation(data.designation);
            setImage(data.image);
            setDescription(data.description);
            setPrecedence(data.precedence);
        }
        fetchAndSetTeam()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('designation', designation)
        itemData.append('image', image)
        itemData.append('description', description)
        itemData.append('precedence', precedence)

        const response = await axios.patch(MEET_THE_TEAM_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }



    return (
        <>
            <div className="navtab_action">
                <div className="nav_tab">
                    <button
                        type="button"
                        className={activeTab === 1 ? 'active' : ''}
                        onClick={() => setActiveTab(1)}
                    >
                        <span>details</span>
                    </button>
                    <button
                        type="button"
                        className={activeTab === 2 ? 'active' : ''}
                        onClick={() => setActiveTab(2)}
                    >
                        <span>write up</span>
                    </button>
                </div>
                {/* <button
                    className="btn_snmd_open"
                    onClick={() => {
                        setShowSecondModal(true)
                        setShowMediaGallery(true)
                    }}
                >
                    <RiVideoAddLine />
                    <span>Media Gallery</span>
                </button> */}
            </div>

            <Form onSubmit={handleSubmit} hasImage>
                <div className={`nav_content ${activeTab === 1 ? 'active' : ''}`}>

                    <ShortTextInput
                        label={`Name`}
                        value={name}
                        placeholder={`Enter Name`}
                        setState={setName}
                    />
                    <ShortTextInput
                        label={`Designation`}
                        value={designation}
                        placeholder={`Enter Designation`}
                        setState={setDesignation}
                    />
                    <ImageInput
                        fieldId='1'
                        state={image}
                        setState={setImage}
                        allowUpdateImage
                    >
                        Upload Card Image
                    </ImageInput>
                    <SelectInput value={precedence} setState={setPrecedence}>
                        <SelectOption optionValue={0} optionText="Select Precedence" />
                        {precedenceSelectOptions()}
                    </SelectInput>



                </div>
                <div className={`nav_content ${activeTab === 2 ? 'active' : ''}`}>
                    <CustomEditor
                        setState={setDescription}
                        data={description}
                    />
                </div>


                <FormSubmitButton text='Update Team' />
            </Form>


        </>
    )
}

export default UpdateTeam