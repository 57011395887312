import { useState, useEffect } from "react"
import axios from "axios"
import { FAQ_API } from "../../../Utilities/APIs"

function ViewFAQs({

    //common props
    targetID

}) {

    const [faq, setFaq] = useState(null)

    useEffect(() => {

        async function fetchAndSetFaq() {

            const { data } = await axios.get(FAQ_API + 'getSingleFaq/' + targetID)
            setFaq(data);
        }
        fetchAndSetFaq()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {faq &&
                <>
                    <h1>Description</h1>
                    <p>{faq.name}</p>

                    <h1>Description</h1>
                    <div
                        dangerouslySetInnerHTML={{ __html: faq.description }}
                    />

                </>
            }
        </div>
    )
}

export default ViewFAQs