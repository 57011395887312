import SmallSideBarItem from './SmallSideBarItem/SmallSideBarItem';

import { RiGroupLine, RiHome2Line, RiInformationLine, RiSettings2Line, RiQuestionLine, RiStackFill, RiInboxLine } from 'react-icons/ri';

import './SideBar.css';

function SideBar() {

    return (
        <>
            <section className="small_sidebar">
                <div className='container'>

                    <SmallSideBarItem
                        link='/'
                        dataTip='Dashboard'
                        dataFor='dashboard'
                    >
                        <RiHome2Line />
                    </SmallSideBarItem>


                    <SmallSideBarItem
                        link='/aboutScreen'
                        dataTip='About'
                        dataFor='about'
                    >
                        <RiInformationLine />
                    </SmallSideBarItem>

                    <SmallSideBarItem
                        link='/faq'
                        dataTip='FAQ'
                        dataFor='FAQs'
                    >
                        <RiQuestionLine />
                    </SmallSideBarItem>

                    <SmallSideBarItem
                        link='/resources'
                        dataTip='Resources'
                        dataFor='resources'
                    >
                        <RiStackFill />
                    </SmallSideBarItem>
                    <SmallSideBarItem
                        link='/contacts'
                        dataTip='Consultancy Request'
                        dataFor='consultancy Request'
                    >
                        <RiInboxLine />
                    </SmallSideBarItem>

                    <SmallSideBarItem
                        link='/usersScreen'
                        dataTip='Users'
                        dataFor='Users'
                    >
                        <RiGroupLine />
                    </SmallSideBarItem>

                    <SmallSideBarItem
                        link='/settingsScreen'
                        dataTip='Settings'
                        dataFor='settings'
                    >
                        <RiSettings2Line />
                    </SmallSideBarItem>
                </div>
            </section>
        </>
    )
}

export default SideBar
