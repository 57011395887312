import { useState, useEffect } from "react"
import axios from "axios"

import { ABOUT_API } from "../../../../Utilities/APIs"
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor"
import Form from "../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"


function UpdateAbout({ targetID, setShowUpdateForm, setShowModal, triggerFetch }) {

    const [activeTab, setActiveTab] = useState(2);
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')

    useEffect(() => {

        async function fetchAndSetAbout() {
            const { data } = await axios.get(ABOUT_API + 'getSingleAbout/' + targetID)
            setDescription(data.description);
            setImage(data.image)
        }
        fetchAndSetAbout()

    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('image', image)
        itemData.append('description', description)

        const response = await axios.patch(ABOUT_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }


    return (
        <>
            <Form onSubmit={handleSubmit} hasImage>
                    <ImageInput
                        state={image}
                        setState={setImage}
                        allowUpdateImage
                    >
                        Upload Image
                    </ImageInput>

                <div className="navtab_action">
                    <div className="nav_tab">
                        <button
                            type="button"
                            className={activeTab === 2 ? 'active' : ''}
                            onClick={() => setActiveTab(2)}
                        >
                            <span>write up</span>
                        </button>
                    </div>
                </div>

                <div className={`nav_content ${activeTab === 2 ? 'active' : ''}`}>
                    <CustomEditor
                        setState={setDescription}
                        data={description}
                    />
                </div>

                <FormSubmitButton text='Update About' />
            </Form>


        </>
    )
}

export default UpdateAbout