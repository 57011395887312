import { useState, useEffect } from "react"
import axios from "axios"

import { FEATURED_TESTIMONIAL_API } from "../../../../Utilities/APIs"

function ViewFeaturedTestimonial({

    //common props
    targetID, employee

}) {

    const [featuredTestimonial, setFeaturedTestimonial] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        async function fetchAndSetFeaturedTestimonial() {

            const { data } = await axios.get(FEATURED_TESTIMONIAL_API + 'getSingleFeaturedTestimonial/' + targetID ,config)
            setFeaturedTestimonial(data);
        }
        fetchAndSetFeaturedTestimonial()
    }, [targetID ,employee.token])

    return (
        <div className="crud_view_content">

            {featuredTestimonial &&
                <>
                    <h1>Name</h1>
                    <p>{featuredTestimonial.testimonial.name}</p>

                    <h1>Precedence</h1>
                    <p>{featuredTestimonial.precedence}</p>

                </>
            }
        </div>
    )
}

export default ViewFeaturedTestimonial