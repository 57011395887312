import { useState, useEffect } from "react"
import axios from "axios"
import { APPROACH_API } from "../../../Utilities/APIs"

function ViewApproach({

    //common props
    targetID, employee

}) {

    const [approach, setApproach] = useState(null)

    useEffect(() => {

        async function fetchAndSetApproach() {

            const { data } = await axios.get(APPROACH_API + 'getSingleApproach/' + targetID)
            setApproach(data);
        }
        fetchAndSetApproach()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {approach &&
                <>
  
                    <h1>Description</h1>
                    <div
                        dangerouslySetInnerHTML={{ __html: approach.description }}
                    />

                </>
            }
        </div>
    )
}

export default ViewApproach