import { useState, useEffect } from "react"
import axios from "axios"

import { IMAGE_URL, RECENT_PURCHASE_API } from "../../../Utilities/APIs"

function ViewRecentPurchase({

    //common props
    targetID, employee

}) {

    const [recentPurchase, setRecentPurchase] = useState(null)

    useEffect(() => {

        async function fetchAndSetRecentPurchase() {

            const { data } = await axios.get(RECENT_PURCHASE_API + 'getSingleRecentPurchase/' + targetID)
            setRecentPurchase(data);
        }
        fetchAndSetRecentPurchase()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {recentPurchase &&
                <>
                   <h1>Name</h1>
                    <p>{recentPurchase.name}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + recentPurchase.image} alt="" />

                    <h1>Location</h1>
                    <p>{recentPurchase.location}</p>

                    <h1>Price</h1>
                    <p>{recentPurchase.price}</p>

                    <h1>Rent</h1>
                    <p>{recentPurchase.rent}</p>

                    <h1>Bed</h1>
                    <p>{recentPurchase.bed}</p>

                    <h1>Bath</h1>
                    <p>{recentPurchase.bath}</p>

                    <h1>Square Feet</h1>
                    <p>{recentPurchase.sqFt}</p>

                    <h1>Garage</h1>
                    <p>{recentPurchase.garage}</p>

                </>
            }
        </div>
    )
}

export default ViewRecentPurchase