import DNTCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DNTCRUD/DNTCRUD'
import { RESOURCE_API } from '../../../Utilities/APIs'

function Resources() {
    return (
        <DNTCRUD
            // common props
            api={RESOURCE_API}
            screenTopicSingular='Resource'
            screenTopicPlural='Resources'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            allowDelete

            //extra field props
            textDBField={'link'}
            textName={'link'}
        />
    )
}

export default Resources