import { useState, useEffect } from "react"
import axios from "axios"
import { CONTACTS_API } from "../../../Utilities/APIs"


function ViewContact({

    //common props
    targetID, employee

}) {

    const [contact, setContact] = useState(null)
    useEffect(() => {

        async function fetchAndSetInfo() {
            const { data } = await axios.get(CONTACTS_API + targetID)
            setContact(data);
        }
        fetchAndSetInfo()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {contact &&
                <>
                    <h1>First Name</h1>
                    <p>{contact.firstName}</p>

                    <h1>Last Name</h1>
                    <p>{contact.lastName}</p>

                    <h1>Email</h1>
                    <p>{contact.email}</p>

                    <h1>Phone</h1>
                    <p>{contact.phone}</p>

                    <h1>Date</h1>
                    <p>{new Date(contact.date).toLocaleDateString()}</p>

                    <h1>Message</h1>
                    <p>{contact.message}</p>

                </>
            }
        </div>
    )
}

export default ViewContact