import React from 'react'
import { PROCESS_API } from '../../../Utilities/APIs'
import DNPCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DNPCRUD/DNPCRUD'

function Process() {
    return (
        <DNPCRUD
            // common props
            api={PROCESS_API}
            screenTopicSingular='Process'
            screenTopicPlural='Processes'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            allowDelete

            maxPrecedence={7}
        />
    )
}

export default Process