import { useEffect, useState } from "react"
import axios from "axios"

import { FEATURED_RECENT_PURCHASE_API, RECENT_PURCHASE_API } from "../../../../Utilities/APIs"
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../Partials/Layouts/Forms/Form"


function CreateFeaturedRecentPurchase({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

    const [recentPurchase, setRecentPurchase] = useState('')
    const [precedence, setPrecedence] = useState('')

    const [recentPurchases, setRecentPurchases] = useState(null)

    useEffect(() => {

        async function fetchAndSetRecentPurchases() {
            const { data } = await axios.get(RECENT_PURCHASE_API)
            setRecentPurchases(data)
        }
        fetchAndSetRecentPurchases()


    }, [])

    function precedenceSelectOptions() {
        const rows = [];
        for (let i = 1; i <= 6; i++) {
            rows.push(<SelectOption key={i} optionText={i} optionValue={i} />);
        }
        return rows;
    }

    async function handleSubmit(e) {

        e.preventDefault()

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }
        const itemData = new FormData()

        itemData.append('recentPurchase', recentPurchase)
        itemData.append('precedence', precedence)



        const response = await axios.post(FEATURED_RECENT_PURCHASE_API, itemData, config)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <div className="input_group">
                <label className='input_field_label'>
                    Recent Purchase
                </label>
                <select
                    value={recentPurchase}
                    onChange={(e) => setRecentPurchase(e.target.value)}
                    className="input_field"
                    placeholder="propertyCategory"
                >
                    <option value="0" hidden>Select    Recent Purchase</option>
                    {recentPurchases?.map((recentPurchase => (
                        <option value={recentPurchase._id} key={recentPurchase._id}>
                            {recentPurchase?.name}
                        </option>
                    )))}
                </select>
            </div>
            <SelectInput value={precedence} setState={setPrecedence}>
                <SelectOption optionValue={0} optionText="Select Precedence" />
                {precedenceSelectOptions()}
            </SelectInput>


            <FormSubmitButton text='Create Featured Recent Purchase' />
        </Form>
    )
}

export default CreateFeaturedRecentPurchase