import React from 'react'
import DINCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DINCRUD/DINCRUD'
import { CLIENT_JOURNEY_API } from '../../../Utilities/APIs'

function ClientJourney() {
    return (
        <DINCRUD
            // common props
            api={CLIENT_JOURNEY_API}
            screenTopicSingular='Client Journey'
            screenTopicPlural='Client Journey'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            allowDelete

        />
    )
}

export default ClientJourney