import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../Redux/Features/Auth/authSlice'

import './App.css'

import NavBar from './Partials/Sections/NavBar/NavBar'
import SideBar from './Partials/Sections/SideBar/SideBar'
import Login from './Screens/AuthScreens/Login/Login'
import Dashboard from './Screens/Dashboard/Dashboard'
import InviteEmployees from './Screens/AuthScreens/InviteEmployees/InviteEmployees'
import EmployeesScreen from './Screens/AuthScreens/EmployeesScreen'
import Employees from './Screens/AuthScreens/Employees/Employees'
import AppSettings from './Screens/AppSettings/AppSettings'
import Register from './Screens/AuthScreens/Register/Register'
import SettingsScreen from './Screens/Settings/SettingsScreen'
import RecoverPassOne from './Screens/AuthScreens/RecoverPassOne'
import RecoverPassThree from './Screens/AuthScreens/RecoverPassThree'

import ContactInfosScreen from './Screens/ContactInfosScreen/ContactInfosScreen'
import UsersScreen from './Screens/UsersScreen/UsersScreen'

import Customers from './Screens/UsersScreen/Customers/Customers'
import EmployeeScreen from './Screens/AuthScreens/Employees/EmployeeScreen'
import ClientJourney from './Screens/HomeScreen/ClientJourney'
import Process from './Screens/HomeScreen/Process'
import SocialLinks from './Screens/SocialLinks/SocialLinks'
import Testimonials from './Screens/HomeScreen/Testimonials'
import AboutScreen from './Screens/AboutScreen/AboutScreen'
import About from './Screens/AboutScreen/About/About'
import Contacts from './Screens/Contacts/Contacts'
import FeaturedTestimonial from './Screens/HomeScreen/FeaturedTestimonial/FeaturedTestimonial'
import RecentPurchase from './Screens/RecentPurchase/RecentPurchase'
import FeaturedRecentPurchase from './Screens/RecentPurchase/FeaturedRecentPurchase/FeaturedRecentPurchase'
import Approach from './Screens/Approach/Approach'
import Teams from './Screens/Teams/Teams'
import RecentPurchaseFeatures from './Screens/RecentPurchase/RecentPurchaseFeatures'
import FAQs from './Screens/FAQScreen/FAQs'
import Resources from './Screens/Resources/Resources'




function App() {

  const { employee } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        dispatch(logout())
      }
      return Promise.reject(err);
    }
  );

  return (
    <section>
      <BrowserRouter>
        <NavBar />
        <main>
          {employee && <SideBar />}
          <section className='screen-container'>

            <Routes>

              <Route path='/login' element={!employee ? <Login /> : <Navigate to={'/'} />} />
              <Route path='/register/:token' element={!employee ? <Register /> : <Navigate to={'/'} />} />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route path="/resetEmployeePassword/:token" element={<RecoverPassThree />} />

              <Route path='/employeeScreens' element={employee ? <EmployeesScreen /> : <Navigate to={'/login'} />} />
              <Route path='/employeeInvites' element={employee ? <InviteEmployees /> : <Navigate to={'/login'} />} />
              <Route path='/employeeScreen' element={employee ? <EmployeeScreen /> : <Navigate to={'/login'} />} />
              <Route path='/employees' element={employee ? <Employees /> : <Navigate to={'/login'} />} />
              <Route path='/customers' element={employee ? <Customers /> : <Navigate to={'/login'} />} />

              <Route path='/' element={employee ? <Dashboard /> : <Navigate to={'/login'} />} />
              <Route path='/clientJourney' element={employee ? <ClientJourney /> : <Navigate to={'/login'} />} />
              <Route path='/process' element={employee ? <Process /> : <Navigate to={'/login'} />} />
              <Route path='/testimonials' element={employee ? <Testimonials /> : <Navigate to={'/login'} />} />
              <Route path='/featuredTestimonials' element={employee ? <FeaturedTestimonial /> : <Navigate to={'/login'} />} />
              <Route path='/aboutScreen' element={employee ? <AboutScreen /> : <Navigate to={'/login'} />} />
              <Route path='/about' element={employee ? <About /> : <Navigate to={'/login'} />} />
              <Route path='/approach' element={employee ? <Approach /> : <Navigate to={'/login'} />} />
              <Route path='/teams' element={employee ? <Teams /> : <Navigate to={'/login'} />} />
              <Route path='/recentPurchase' element={employee ? <RecentPurchase /> : <Navigate to={'/login'} />} />
              <Route path='/featuredRecentPurchase' element={employee ? <FeaturedRecentPurchase /> : <Navigate to={'/login'} />} />
              <Route path='/recentPurchaseFeatures/:parentID' element={employee ? <RecentPurchaseFeatures /> : <Navigate to={'/login'} />} />
              <Route path='/FeaturedRecentPurchase' element={employee ? <FeaturedRecentPurchase /> : <Navigate to={'/login'} />} />
              <Route path='/faq' element={employee ? <FAQs /> : <Navigate to={'/login'} />} />
              <Route path='/resources' element={employee ? <Resources /> : <Navigate to={'/login'} />} />
        
              <Route path='/settingsScreen' element={employee ? <SettingsScreen /> : <Navigate to={'/login'} />} />
              <Route path='/socialLinks' element={employee ? <SocialLinks /> : <Navigate to={'/login'} />} />
              <Route path='/appSettings' element={employee ? <AppSettings /> : <Navigate to={'/login'} />} />
              <Route path='/contactInfos' element={employee ? <ContactInfosScreen /> : <Navigate to={'/login'} />} />
              <Route path='/contacts' element={employee ? <Contacts /> : <Navigate to={'/login'} />} />
              <Route path='/usersScreen' element={employee ? <UsersScreen /> : <Navigate to={'/login'} />} />

            </Routes>

          </section>
        </main>
      </BrowserRouter>
    </section>
  )
}

export default App
