import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import {  EMPLOYEE_INVITES_API } from '../../../../Utilities/APIs';
import { register, reset } from '../../../../Redux/Features/Auth/authSlice';
import Form from '../../../Partials/Layouts/Forms/Form';
import ShortTextInput from '../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput'
import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton';
import PasswordInput from '../../../Partials/Layouts/Forms/FormInputs/PasswordInput/PasswordInput';
import ImageInput from '../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput';
import ScreenHolder from '../../../Partials/Layouts/ScreenHolder/ScreenHolder';

import './Register.css';

function Register() {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [dp, setDp] = useState(null)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { employee, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth);
  const { token } = useParams()
  // console.log('token: ', token)

  useEffect(() => {

    async function fetchAndSetEmail() {
      console.log('yooo')
      const { data } = await axios.get(EMPLOYEE_INVITES_API + 'getEmailFromToken/' + token)
      // console.log('Data: ', data)
      setEmail(data.email)
    }

    fetchAndSetEmail()
  }, [token])

  useEffect(() => {

    if (isError) {
      console.log(message);
    }

    if (isSuccess || employee) {
      navigate('/');
    }

    dispatch(reset());

  }, [employee, isError, isSuccess, isLoading, message, dispatch, navigate])

  function handleSubmit(e) {

    e.preventDefault();

    if (password !== password2) {
      console.log('Passwords do not match!')

    } else {

      const employeeData = new FormData();

      employeeData.append('name', name)
      employeeData.append('email', email)
      employeeData.append('password', password)
      employeeData.append('dp', dp)
      employeeData.append('level', 'employee')

      dispatch(register(employeeData));

      setName('')
      setEmail('')
      setPassword('')
      setPassword2('')

    }

  }

  return (
    <ScreenHolder>
      <div className="form_wrapper register">
      <h3>Register</h3>
        <Form
          onSubmit={handleSubmit}
          hasImage
        >
          <ShortTextInput
            label='Name'
            value={name}
            placeholder='Please Enter your Name'
            setState={setName}
          />
          <PasswordInput
            label='Password'
            value={password}
            placeholder='Please Enter your Password'
            setState={setPassword}
          />
          <PasswordInput
            label='Confirm Password'
            value={password2}
            placeholder='Please Confirm your Password'
            setState={setPassword2}
          />
          <ImageInput
            allowCreateImage
            state={dp}
            setState={setDp}
            fieldId={'employeeDp'}
            children={'Display Picture'}
          />

          <FormSubmitButton text='Create Account' />

        </Form>
      </div>
    </ScreenHolder>
  )
}

export default Register