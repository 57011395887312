import { useState } from "react"
import axios from "axios"

import { FAQ_API, } from "../../../Utilities/APIs"
import Form from "../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"


function CreateFAQs({ setShowCreateForm, setShowModal, triggerFetch }) {
    const [activeTab, setActiveTab] = useState(2);

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')


    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('description', description)

        const response = await axios.post(FAQ_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} >
            <ShortTextInput
                label={'Name'}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <div className="navtab_action">
                <div className="nav_tab">
                    <button
                        type="button"
                        className={activeTab === 2 ? 'active' : ''}
                        onClick={() => setActiveTab(2)}
                    >
                        <span>write up</span>
                    </button>
                </div>
            </div>

            <div className={`nav_content ${activeTab === 2 ? 'active' : ''}`}>
                <CustomEditor
                    setState={setDescription}
                />
            </div>
            <FormSubmitButton text='Create FAQ' />
        </Form>
    )
}

export default CreateFAQs