import {  RiFacebookLine, RiMapPin2Line, RiMessage2Line, RiMessageLine, RiSettings2Fill } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function SettingsScreen() {
    return (
        <NavCardList numOfCards={'six'}>
            <NavCard cardName={'App Settings'} navCardLink={'/appSettings'} ><RiSettings2Fill /></NavCard>
            <NavCard cardName={'Social Links'} navCardLink={'/socialLinks'} ><RiFacebookLine /></NavCard>
            <NavCard cardName={'Contact Infos'} navCardLink={'/contactInfos'} ><RiMapPin2Line /></NavCard>
            {/* <NavCard cardName={'Contacts'} navCardLink={'/contacts'} ><RiInboxLine /></NavCard> */}
            <NavCard cardName={'Testimonials'} navCardLink={'/testimonials'} ><RiMessage2Line /></NavCard>
            <NavCard cardName={'Featured Testimonials'} navCardLink={'/featuredTestimonials'} ><RiMessageLine /></NavCard>
            <NavCard cardName={'Featured Recent Purchase'} navCardLink={'/featuredRecentPurchase'} ><RiMessageLine /></NavCard>

        </NavCardList>
    )
}

export default SettingsScreen