import { backendUrl, spacesBucketUrl } from "../Constants"

export const BASE_URL = backendUrl
export const IMAGE_URL = spacesBucketUrl
export const API_URL = BASE_URL + 'api/'

export const EMPLOYEES_API = API_URL + 'users/employees/'
export const EMPLOYEE_INVITES_API = API_URL + 'users/employeeInvites/'
export const DESIGNATIONS_API = API_URL + 'designations/'
export const EMPLOYEE_DESIGNATIONS_API = API_URL + 'employeeDesignations/'
export const CUSTOMERS_API = API_URL + 'customers/'

export const APP_SETTINGS_API = API_URL + 'appSettings/'
export const SOCIAL_LINK_API = API_URL + 'socialLink/'
export const MEET_THE_TEAM_API = API_URL + 'meetTheTeam/'
export const ABOUT_API = API_URL + 'about/'
export const APPROACH_API = API_URL + 'approach/'
export const CLIENT_JOURNEY_API = API_URL + 'clientJourney/'
export const RECENT_PURCHASE_API = API_URL + 'recentPurchase/'
export const FEATURED_RECENT_PURCHASE_API = API_URL + 'featuredRecentPurchase/'
export const RECENT_PURCHASE_FEATURES_API = API_URL + 'recentPurchaseFeatures/'
export const PROCESS_API = API_URL + 'process/'
export const FEATURED_TESTIMONIAL_API = API_URL + 'featuredTestimonial/'
export const TESTIMONIAL_API = API_URL + 'testimonial/'
export const FAQ_API = API_URL + 'faq/'
export const RESOURCE_API = API_URL + 'resource/'

export const CONTACTS_API = API_URL + 'contact/'
export const CONTACT_INFOS_API = API_URL + 'contactInfo/'
