import { useState } from "react"
import axios from "axios"

import { ABOUT_API } from "../../../../Utilities/APIs"
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor"
import Form from "../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"

// import './CreateApproach.css'

function CreateAbout({ setShowCreateForm, setShowModal, triggerFetch }) {

    const [activeTab, setActiveTab] = useState(2);

    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')

    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('image', image)
        itemData.append('description', description)

        const response = await axios.post(ABOUT_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }


    return (
        <>
            <div className="navtab_action">
                <div className="nav_tab">
                    <button
                        type="button"
                        className={activeTab === 2 ? 'active' : ''}
                        onClick={() => setActiveTab(2)}
                    >
                        <span>write up</span>
                    </button>
                </div>
            </div>

            <Form onSubmit={handleSubmit} hasImage>
                <ImageInput setState={setImage} allowCreateImage>Upload Image</ImageInput>
                <div className={`nav_content ${activeTab === 2 ? 'active' : ''}`}>
                    <CustomEditor
                        setState={setDescription}
                    />
                </div>

                <FormSubmitButton text='Create About' />
            </Form>
        </>
    )
}

export default CreateAbout